@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');

html,
body {
    height: 100%;
    margin: 0;
    background: linear-gradient(180deg, #000080, #7D1B7E);
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}


.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px 0;
    font-size: 1.75em;
    font-family: 'Dancing Script', cursive;
    /* font-family: 'Pacifico', cursive; */
    /* font-family: 'Great Vibes', cursive; */
    /* font-family: 'Lobster', cursive; */
    /* font-family: 'Allura', cursive; */
    /* font-family: 'Pinyon Script', cursive; */
    /* font-family: 'Kaushan Script', cursive; */
    /* font-family: 'Tangerine', cursive; */
    /* font-family: 'Sacramento', cursive; */
    /* font-family: 'Alex Brush', cursive; */
}

.footer a {
    color: inherit; /* Ensures the link has the same text color as the footer */
    text-decoration: none; /* Removes the underline from the link */
}

.footer a:hover {
    text-decoration: underline; /* Adds an underline on hover */
}


.hiddenEvent {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
}

.fadeInEvent {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}


.afterSpacer {
    padding-left: 40px;
    margin-bottom: 10px;
    padding-bottom: 20px;
}

.eventContainer,
.groupedEventContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.eventContainer {
    padding: 5px 50px 15px 0;
}

.groupedEventContainer {
    padding: 1px 50px 1px 0;
}

.loading {
    height: 100vh;
    overflow: hidden;
}

.eventsList {
    overflow: hidden;
    transition: none;
}

.eventDateGroup {
    padding: 5px 0 5px 40px;
    color: #FFFFFF;
    font-style: italic;
}

h2 {
    padding: 5px 50px 0px 40px;
    position: relative;
    margin-bottom: 0;
}

h2::after {
    content: '';
    display: block;
    margin-top: 5px;
    height: 1px;
    background-color: white;
}

ul {
    margin-top: 0;
}

.eventDetails {
    flex: 4;
}

.eventDetailsLink {
    flex: 4;
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Keeps the color of text same as the parent element */
}

.eventDetailsLink:hover, .eventDetailsLink:active, .eventDetailsLink:focus {
    text-decoration: none; /* Removes underline from links on hover/active/focus */
    color: inherit; /* Keeps the color of text same as the parent element on hover/active/focus */
}

.eventDetailsLink:hover strong {
    text-decoration: underline; /* Underlines the event name on hover */
}

.eventTime {
    flex: 1;
    text-align: right;
    font-size: 1em;
}

.no-events {
    list-style-type: none;
    font-style: italic;
    padding: 5px 50px 15px 10px;
}

.noupcoming-events {
    list-style-type: none;
    font-style: italic;
    padding: 5px 50px 0 10px;
}

.clockStyle {
    padding: 10px 50px 0 0;
    text-align: right;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5em; 
}

.event-enter, .noevent-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding-top: 0; 
    padding-bottom: 0; 
}

.event-enter-active {
    max-height: 90px; 
    opacity: 1;
    padding-top: 5px; 
    padding-bottom: 15px; 
    transition: 
        max-height 3s ease-in-out, 
        opacity 5s ease-in-out, 
        padding-top 3s ease-in-out, 
        padding-bottom 3s ease-in-out; 
}

.event-exit, .noevent-exit {
    max-height: 90px;
    opacity: 1;
    padding-top: 5px; 
    padding-bottom: 15px; 
}

.event-exit-active {
    max-height: 0;
    opacity: 0;
    padding-top: 0; 
    padding-bottom: 0; 
    transition: 
        max-height 5s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 5s ease-in-out, 
        padding-bottom 5s ease-in-out; 
}


.noevent-enter-active {
    max-height: 90px; 
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 15px; 
    transition: 
        max-height 5s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 5s ease-in-out, 
        padding-bottom 5s ease-in-out;
}

.noevent-exit-active {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: 
        max-height 3s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 3s ease-in-out, 
        padding-bottom 3s ease-in-out; 
}


.upcomingevent-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding-top: 0; 
    padding-bottom: 0; 
}

.upcomingevent-enter-active {
    max-height: 20px; 
    opacity: 1;
    padding-top: 1px; 
    padding-bottom: 1px; 
    transition: 
        max-height 1s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 1s ease-in-out, 
        padding-bottom 1s ease-in-out; 
}

.upcomingevent-exit {
    max-height: 20px;
    opacity: 1;
    padding-top: 1px; 
    padding-bottom: 1px; 
}

.upcomingevent-exit-active {
    max-height: 0;
    opacity: 0;
    padding-top: 0; 
    padding-bottom: 0; 
    transition: 
        max-height 3s ease-in-out, 
        opacity 1s ease-in-out, 
        padding-top 3s ease-in-out, 
        padding-bottom 3s ease-in-out; 
}


@media only screen and (max-width: 768px) {
    html, body {
        /* Adjust the styling for mobile screens */
        overflow: auto; /* Allow scrolling on mobile */
    }

    .footer {
        font-size: 1.25em; /* Smaller font size for mobile */
    }

    .eventContainer, .groupedEventContainer {
        flex-direction: column; /* Stack elements vertically on small screens */
        padding: 5px 10px; /* Adjust padding for smaller screens */
        align-items: flex-start;
    }

    .eventDetails, .eventTime {
        flex: none; /* Adjust flex settings for mobile */
        text-align: left; /* Align text to the left */
        font-size: 0.9em; /* Smaller text for mobile */
    }

    h2 {
        font-size: 1.2em; /* Adjust heading size for mobile */
        padding: 5px 10px; /* Adjust heading padding */
    }

    .clockStyle {
        padding: 10px; /* Adjust clock padding */
        text-align: center; /* Center the clock */
    }

    /* Override the transition effects for mobile */
    .hiddenEvent, .fadeInEvent, .event-enter, .noevent-enter, 
    .event-enter-active, .event-exit, .noevent-exit, 
    .event-exit-active, .noevent-enter-active, .noevent-exit-active,
    .upcomingevent-enter, .upcomingevent-enter-active,
    .upcomingevent-exit, .upcomingevent-exit-active {
        opacity: 1;
        visibility: visible;
        transition: none; /* Remove transition effects */
        max-height: none; /* Remove max-height restrictions */
        overflow: visible; /* Ensure content is not hidden */
        padding-top: initial; /* Reset any specific padding */
        padding-bottom: initial; /* Reset any specific padding */
    }

}